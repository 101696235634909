import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const page404 = () => {
  return (
    <Layout>
      <h1>Error page</h1>
      <p>404 Error page</p>
      <Link to="/">Home</Link>
    </Layout>
  )
}

export default page404
